import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      pad: 1080,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontSize: 10,
    fontFamily: [
      'Crimson Text',
      'Georgia',
      'Times New Roman',
      'serif',
      '-apple-system',
      'BlinkMacSystemFont'
    ].join(','),
    body1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1.4,
      opacity: 0.75,
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.6,
      width: 'max-content',
      margin: '1.2rem auto',
      borderBottom: 'solid 1.1px #00000099',
      textAlign: 'center',
      letterSpacing: '-0.03em'
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.6,
      width: 'max-content',
      margin: '1.2rem auto',
      borderBottom: 'solid 0.5px #00000039',
      textAlign: 'center',
    },
    menulist: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.5,
    },
  },
});
theme = createTheme(theme, {
  typography: {
    body1: {
      color: theme.palette.grey[800],
      [theme.breakpoints.down('lg')]: {
        fontWeight: 400,
      },
      [theme.breakpoints.down('pad')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm')]: {
        fontWeight: 700,
      },
    },
    body2: {
      color: theme.palette.grey[800],
      [theme.breakpoints.down('lg')]: {
        fontWeight: 400,
      },
      [theme.breakpoints.down('pad')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm')]: {
        fontWeight: 700,
      },
    },
    caption: {
      color: theme.palette.grey[700],
      [theme.breakpoints.down('lg')]: {
        fontWeight: 400,
      },
      [theme.breakpoints.down('pad')]: {
        fontWeight: 400,
      },
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600,
      },
    },
    subtitle1: {
      color: theme.palette.grey[800],
      [theme.breakpoints.down('lg')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('pad')]: {
        fontWeight: 600,
      },
      [theme.breakpoints.down('md')]: {
        fontWeight: 700,
      },
      [theme.breakpoints.down('sm')]: {
        fontWeight: 700,
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default function MuiWrapper(props) {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

MuiWrapper.propTypes = {
  children: PropTypes.node,
};